<template>
  <div class="jobs" :style="style">
    <h2>Work Experience</h2>

    <div v-for="job in jobs" :key="job.company.name">
      <Job :job="job" />
    </div>
  </div>
</template>

<style lang="less" scoped>
h2 {
  margin: 0.5rem 0 1rem 0;
}

.jobs {
  > div {
    & + div {
      border-top: 1px solid var(--grey-background);
    }
  }
}
</style>

<script lang="ts">
import Job from "@/components/Job.vue";
import { Job as JobType } from "@/types/Job";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    Job,
  },
  props: {
    jobs: {
      type: Object as PropType<JobType[]>,
    },
  },
});
</script>
