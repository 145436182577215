<template>
  <div class="home">
    <DefaultLayout :portfolio="portfolio">
      <div class="content">
        <Jobs :jobs="portfolio.jobs" />
      </div>
    </DefaultLayout>
  </div>
</template>

<style lang="less" scoped>
.content {
  margin: 0 auto;
  padding: 1em;
  max-width: 40rem;
}
</style>

<script lang="ts">
import DefaultLayout from "@/layouts/Default.vue";
import Jobs from "@/components/Jobs.vue";
import pData from "@/data/Portfolio";
import { Portfolio } from "@/types/Portfolio";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DefaultLayout,
    Jobs,
  },
  data() {
    const portfolio: Portfolio = pData;

    return {
      portfolio,
    };
  },
});
</script>
