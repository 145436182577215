import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faExternalLinkAlt,
  faMapMarkerAlt,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faMedium,
  faStackExchange,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEnvelope,
  faExternalLinkAlt,
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faMapMarkerAlt,
  faMedium,
  faQuoteLeft,
  faQuoteRight,
  faStackExchange
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
createApp(App as any)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
