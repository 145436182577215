import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a9cbe63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createVNode(_component_Header, { portfolio: _ctx.portfolio }, null, 8, ["portfolio"])
      ], true)
    ]),
    _createElementVNode("main", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("footer", null, [
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        _createVNode(_component_Footer, { portfolio: _ctx.portfolio }, null, 8, ["portfolio"])
      ], true)
    ])
  ]))
}