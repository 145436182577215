import { Portfolio } from "@/types/Portfolio";

const portfolio: Portfolio = {
  me: {
    name: "Hitesh Pachpor",
    about: [
      "I have 12 years of experience in software development and 8 years leading diverse teams.",
      "I bring a proven track record in managing small to medium sized engineering teams, developing and maintaining reliable software systems, and delivering impactful products across multiple domains using agile methodologies.",
      "I am well versed with JavaScript, Node.js, MySQL, Amazon Web Services (AWS), DevOps and SRE practices, having also worked on PHP, Python, React.js, Vue.js, Postgres, MongoDB, Kubernetes, and other relevant technologies over the years.",
    ],
    photo: "dp.jpeg",
    location: "Dubai, UAE",
    contact: {
      email: "hiteshspac@gmail.com",
    },
    social: {
      linkedin: "https://www.linkedin.com/in/hitesh-pachpor/",
      github: "https://github.com/hiteshpachpor",
      medium: "https://hiteshspac.medium.com",
      stackExchange:
        "https://stackexchange.com/users/2137427/hiteshspac?tab=accounts",
    },
  },
  jobs: [
    {
      company: {
        name: "Hello Chef",
        about:
          "Hello Chef is a weekly meal subscription service, delivering pre-portioned ingredients and easy-to-follow recipe cards across the UAE.",
        location: "Dubai, UAE",
        website: "https://hellochef.me",
        logo: "companies/hello-chef.png",
      },
      period: {
        from: new Date(2020, 8),
        to: new Date(2024, 8),
      },
      current: true,
      position: "Senior Engineering Manager",
      keyResults: [
        "Reporting to the CEO, my main objectives were to build and manage the Engineering team of 10 people (cross-functional), develop the technical strategy for the business, lead on its execution with my team, and to create a strong culture of software engineering.",
        "Launched several new products and features over the last 3 years that have resulted in a 50% drop in weekly churn, 13% increase in order frequency, and an increase in ARPU.",
        "Launched the Hello Chef mobile app on App Store to offer a better user experience. 52% of subscribers use it weekly and it's in the 75th percentile on the “Day 28 Retention” metric in the Food & Drinks Apps category in UAE since it launched in March '24.",
        "Established a new ML Engineering team to unlock the potential of machine learning within the business. Reduced model operationalization time from 1 month to 3 days and improved model reliability by 50%. We've released a total of 9 models so far like recommendation, forecasting, scheduling, etc.",
        "Developed a group of in-house products that manage inventory (inbound, storage, stock out, cycle counting), printing (cards, labels), and production line (recipe box assembly and order packing) to fulfill the unique and complex needs of the Operations function.",
        "Migrated all of Hello Chef services from AWS Mumbai to AWS Dubai with minimal downtime. Introduced tools such as Terraform, Helm Charts, ArgoCD, and Grafana to improve infrastructure reliability and observability.",
        "Created a culture of performance measurement and accountability through OKRs, service level KPIs and SLOs, team level KPIs and DORA metrics, and individual scorecards. Encouraged collective learning through weekly learning sessions, rolled out Scrum and XP practices.",
      ],
      tags: [
        "JavaScript",
        "Node.js",
        "MySQL",
        "AWS",
        "Kubernetes",
        "Terraform",
        "Vue.js",
        "React Native",
        "Laravel (PHP)",
        "Python",
        "PostgreSQL",
      ],
    },
    {
      company: {
        name: "Paytm Insider",
        about:
          "Paytm Insider is a platform that helps you discover and buy the best in sports, music, comedy, travel, food & lots more in your city.",
        location: "Mumbai, India",
        website: "https://insider.in",
        logo: "companies/paytm-insider.png",
      },
      period: {
        from: new Date(2019, 6, 1),
        to: new Date(2020, 8, 30),
      },
      current: false,
      position: "Technical Program Manager",
      keyResults: [
        "Reporting to the Head of Engineering, my main objectives were to oversee the delivery of key projects in Paytm Insider's Events vertical and to coordinate with other teams/departments.",
        "Helped launch Indian Historical Monuments ticket sale on insider.in, a brand new category for the business.",
        "Helped deliver a seamless event ticket purchase flow on Paytm app's Events section.",
        "Managed technology for onsite box-offices (offline) at several high profile events like NH7 Weekender.",
        "Streamlined and scaled technical support processes for both internal customers and end-users, resulting in faster issue resolution and more customer satisfaction.",
      ],
      tags: [
        "JavaScript",
        "NodeJS",
        "React",
        "MongoDB",
        "AWS",
        "Kubernetes",
        "Kong",
        "Microservices Architecture",
      ],
    },
    {
      company: {
        name: "USTRAA",
        about:
          "Ustraa is a range of grooming products for men. Helping our Bros look their best since 2015.",
        location: "New Delhi, India",
        website: "https://ustraa.com",
        logo: "companies/ustraa-2.png",
      },
      period: {
        from: new Date(2015, 6),
        to: new Date(2019, 6),
      },
      current: false,
      position: "Software Development Manager",
      keyResults: [
        "Reporting to the Head of E-commerce, my main objectives were to lead on the development of projects/features on the Ustraa and Happily Unmarried e-commerce websites.",
        "Scaled website capabilities by 3x by launching a brand new ustraa.com website & mobile apps without increasing infrastructure budget.",
        "Unlocked the ability to set up highly customizable marketing automation workflows by building an in-house tool inspired by Mixpanel.",
        "Built an in-house tool that helped the business reduce losses due to order returns & customer grievances by 30% by implementing a rule based dispatch module with SLA tracking and flagging risky orders.",
      ],
      tags: [
        "JavaScript",
        "Node.js",
        "AWS",
        "MySQL",
        "PHP",
        "React.js",
        "Magento",
        "Laravel",
        "Python",
        "Angular.js",
        "MongoDB",
        "Swift",
        "Java",
        "Elastic",
      ],
    },
    {
      company: {
        name: "Iksula Services Pvt Ltd",
        about:
          "Iksula helps brands build and manage their eCommerce experiences.",
        location: "Mumbai, India",
        website: "https://iksula.com",
        logo: "companies/iksula.png",
      },
      period: {
        from: new Date(2013, 0, 1),
        to: new Date(2015, 5, 30),
      },
      current: false,
      position: "Software Developer → Team Lead",
      keyResults: [
        "Worked with clients from India, USA, Canada to build their e-commerce platforms using the Magento framework.",
        "Set up standard practices in VCS, coding standards, client project requirement management for the entire department.",
        "Helped happilyunmarried.com scale 20x on their website.",
        "Built a platform for a US-based client to help customers compare audio equipments and musical instruments.",
      ],
      tags: [
        "Magento",
        "Laravel",
        "MySQL",
        "HTML",
        "CSS",
        "jQuery",
        "AngularJS",
      ],
    },
  ],
};

export default portfolio;
