import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-59a179ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "location" }
const _hoisted_5 = { class: "contact" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePhoto = _resolveComponent("ProfilePhoto")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProfilePhoto, {
        src: _ctx.portfolio.me.photo
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.portfolio.me.name), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolio.me.about, (paragraph, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "about",
          key: i
        }, [
          _createElementVNode("p", null, _toDisplayString(paragraph), 1)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_font_awesome_icon, { icon: "map-marker-alt" }),
        _createTextVNode(" " + _toDisplayString(_ctx.portfolio.me.location), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: _ctx.portfolio.me.social.linkedin,
        target: "_blank"
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fab', 'linkedin'] })
      ], 8, _hoisted_6),
      _createElementVNode("a", {
        href: _ctx.portfolio.me.social.github,
        target: "_blank"
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fab', 'github'] })
      ], 8, _hoisted_7),
      _createElementVNode("a", {
        href: `mailto:${_ctx.portfolio.me.contact.email}`,
        target: "_blank"
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "envelope" })
      ], 8, _hoisted_8)
    ])
  ]))
}