<template>
  <div class="tag">
    {{ tag }}
  </div>
</template>

<style lang="less" scoped>
.tag {
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 700;
  border-radius: 1em;
  color: var(--white);
  background-color: var(--light-text-color);
  padding: 0.15em 0.85em 0.3em;
  margin: 0 0.5em 0 0;
}
</style>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    tag: {
      type: Object as PropType<unknown>,
    },
  },
});
</script>
