<template>
  <div class="profile-photo" :style="style">
    <!--  -->
  </div>
</template>

<style lang="less" scoped>
.profile-photo {
  height: calc(100vw * 0.6 - 0.7em);
  width: 60%;
  max-height: 12em;
  max-width: 12em;
  border-radius: 50%;
  overflow: hidden;
  border: 0.35em solid var(--white);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25),
    inset 0px 0px 12px rgba(0, 0, 0, 0.25);
  background-size: cover;

  img {
    display: block;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  computed: {
    style(): string {
      return `background-image: url(${require(`@/assets/${this.src}`)});`;
    },
  },
  props: {
    src: {
      type: Object as PropType<unknown>,
    },
  },
});
</script>
