<template>
  <div class="default">
    <header>
      <slot name="header">
        <Header :portfolio="portfolio" />
      </slot>
    </header>
    <main>
      <slot></slot>
    </main>
    <footer>
      <slot name="footer">
        <Footer :portfolio="portfolio" />
      </slot>
    </footer>
  </div>
</template>

<style lang="less" scoped>
.default {
  height: 100vh;
}
</style>

<script lang="ts">
import Header from "@/components/page/Header.vue";
import Footer from "@/components/page/Footer.vue";
import { defineComponent, PropType } from "vue";
import { Portfolio } from "@/types/Portfolio";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  props: {
    portfolio: {
      type: Object as PropType<Portfolio>,
    },
  },
});
</script>
