import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-88286422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Jobs = _resolveComponent("Jobs")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DefaultLayout, { portfolio: _ctx.portfolio }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Jobs, {
            jobs: _ctx.portfolio.jobs
          }, null, 8, ["jobs"])
        ])
      ], undefined, true),
      _: 1
    }, 8, ["portfolio"])
  ]))
}